<template>
  <SystemErrorBoundary>
    <ul
      style="z-index: 100"
      class="fixed mr-2 mt-1 right-0 bottom-6 font-semibold flex flex-col"
    >
      <li
        v-for="message in errorList"
        :key="message"
        class="rounded-md mt-2 p-4 text-red-900 bg-white border-red-500 border-l-4 shadow-lg"
      >
        {{ message }}
      </li>
      <li
        v-for="message in successList"
        :key="message"
        class="rounded-md mt-2 p-4 text-green-light bg-white border-green border-l-4 shadow-lg"
      >
        {{ message }}
      </li>
      <li
        v-for="message in warningList"
        :key="message"
        class="rounded-md mt-2 p-4 text-yellow-dark bg-white border-yellow-dark border-l-4 shadow-lg"
      >
        <i class="fas fa-exclamation-circle"></i> {{ message }}
      </li>
    </ul>
  </SystemErrorBoundary>
</template>

<script setup>
import { useErrorLog } from "../composables/errorLog";

import SystemErrorBoundary from "./SystemErrorBoundary.vue";

const { errorList, successList, warningList } = useErrorLog();
</script>
