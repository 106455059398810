import { initializeApp } from "firebase/app";
import {
  initializeFirestore,
  persistentLocalCache,
  persistentSingleTabManager,
} from "firebase/firestore";
const productionConfig = {
  apiKey: "AIzaSyCwytVbFlBbQEEuDUqEmPwusOy297ICtX8",
  authDomain: "farmhelp-bfe75.firebaseapp.com",
  projectId: "farmhelp-bfe75",
  storageBucket: "farmhelp-bfe75.appspot.com",
  messagingSenderId: "993713692327",
  appId: "1:993713692327:web:ff2f3c9155544d4f580582",
  measurementId: "G-XC18B1T8NL",
};

const demoConfig = {
  apiKey: "AIzaSyBq8xwZNxETNIjeIcLKdA8DZdEfm3ZPta0",
  authDomain: "farmhelp-demo.firebaseapp.com",
  projectId: "farmhelp-demo",
  storageBucket: "farmhelp-demo.appspot.com",
  messagingSenderId: "5498059801",
  appId: "1:5498059801:web:f1d8ff34c8fec11afe7e88",
};

const devConfig = {
  apiKey: "AIzaSyAlGYaGzX55CTv9B_a5pKuIAncBRvztdoE",
  authDomain: "farmhelp-dev.firebaseapp.com",
  projectId: "farmhelp-dev",
  storageBucket: "farmhelp-dev.appspot.com",
  messagingSenderId: "712079759455",
  appId: "1:712079759455:web:ed7963b950b60fa6fa23ea"
};

export function fbConnect(mode: string) {
  console.log("App status - Connecting to Firebase");
  let app;
  switch (mode) {
    /* eslint-disable */
    // @ts-ignore
    case "dev":
      app = initializeApp(devConfig);
      break;
    case "demo":
      app = initializeApp(demoConfig);
      break;
    default:
      app = initializeApp(productionConfig);
      break;
  }
  const db = initializeFirestore(app, 
    {localCache: 
      persistentLocalCache({tabManager: persistentSingleTabManager({})})
  });

  
}





