import type { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/planning",
    name: "Planning",
    components: {
      default: () => import("./pages/pl-page-index.vue"),
      LeftSidebar: () => import("../../components/farm-side-bar.vue"),
    },
    meta: { requiresAuth: true },
  },
];

export default routes;
