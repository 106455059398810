<template>
  <SystemProcessingModal />
  <SystemErrorMessages />
  <!--   <ReAuthenticationModal />
  <NewPasswordModal :first-sign-in="
  profile.firstSignIn" />
  <AcceptPrivacyAndSubscriptionTermsModal
    :accepted-privacy-and-subscription-terms="
      !profile.acceptedPrivacyAndSubscriptionTerms
    "
  /> -->
  <div
    v-if="!loading"
    class="text-base flex flex-col h-screen w-screen text-white bg-gradient-to-r from-blue-darkest to-green-darkest"
  >
    <TopBar v-if="isMobile" />
    <div class="w-full h-full flex">
      <router-view class="order-2 w-full h-full relative" />
      <router-view
        v-if="!isMobile"
        name="LeftSidebar"
        class="order-1 shrink-0"
      />
    </div>
    <BottomBar v-if="isMobile && !route.path.includes('/user')" />
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { onUnmounted, ref, watchEffect, defineAsyncComponent } from "vue";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useMainStore } from "./stores/main";
import { useSystemState } from "./composables/systemState";
import { analyticsConnect } from "./plugins/analytics";
/* import ReAuthenticationModal from "./components/re-authentication-modal.vue";
import NewPasswordModal from "./components/new-password-modal.vue";
import AcceptPrivacyAndSubscriptionTermsModal from "./components/accept-privacyterms-modal.vue";
*/
import SystemProcessingModal from "./components/SystemProcessingModal.vue";
import SystemErrorMessages from "./components/SystemErrorMessages.vue";
import { useNotifications } from "./modules/notifications/utilities/notifications";

const mainStore = useMainStore();
const { startProcessing, stopProcessing, isMobile } = useSystemState();
const route = useRoute();
const auth = getAuth();

const loading = ref(true);

const TopBar = defineAsyncComponent(async () => {
  if (isMobile.value) return await import("./components/mobile-top-bar.vue");
});
const BottomBar = defineAsyncComponent(async () => {
  if (isMobile.value) return await import("./components/mobile-bottom-bar.vue");
});

onAuthStateChanged(auth, async (user) => {
  loading.value = true;
  startProcessing();
  if (user) {
    mainStore.userToken = user;
    mainStore.email = user.email;
    mainStore.isLoggedIn = true;
    console.log("You are authenticated");
    analyticsConnect();
    await mainStore.getPermissions();
    await mainStore.syncUserWithDb();
    await mainStore.syncFarmWithDb();
    await mainStore.syncUnitsWithDb();
    await mainStore.syncEmployeesWithDb();
    mainStore.setSelectedUnit();
    loading.value = false;
    const { initNotifications } = useNotifications();
    initNotifications();
    stopProcessing();
  } else {
    mainStore.isLoggedIn = false;
    console.log("You are not authenticated");
    stopProcessing();
    loading.value = false;
  }
});

watchEffect(() => {
  if (mainStore.isEmployee) {
    mainStore.selectedEmployee = mainStore.getSelfEmployee;
  }
});

onUnmounted(() => mainStore.disconnect());
</script>
