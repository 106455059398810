import { useMainStore } from "./../stores/main";
import { useFirestore } from "./firestore";
import { ref } from "vue";

const errorList = ref<string[]>([]);
const successList = ref<string[]>([]);
const warningList = ref<string[]>([]);

export function useErrorLog() {
  /**
   * Used to notify the user that an error occured in the ui. Used for predicted errors but that should not happen.
   * @param message Message send to database log
   */
  function setError(message: string) {
    errorList.value.push(
      "Whoops, something went wrong. FarmHelp support has been notified."
    );
    console.log(message);
    setTimeout(() => errorList.value.shift(), 5000);
    const mainStore = useMainStore();
    const data = {
      farmId: mainStore.getFarmId,
      type: "PredictedError",
      errorMessage: message,
    };
    const { fsDocAdd } = useFirestore();
    fsDocAdd(data, "analytics");
  }

  /**
   * Show warning message in UI and send to analytics
   * @param message Warning message shown in UI
   */
  function setWarning(message: string) {
    warningList.value.push(message);
    setTimeout(() => warningList.value.shift(), 5000);
  }

  /**
   * Show success message in UI
   * @param message Success message shown in UI
   */
  function setSuccess(message: string) {
    successList.value.push(message);
    setTimeout(() => successList.value.shift(), 5000);
  }

  /**
   * Register system error and send to analytics
   * @param message Error message
   * @param source File or component where error is located
   * @param functionName Name of the function
   */
  function logError(
    error: Error | unknown,
    source = "undefined",
    functionName = "",
    optionalInfo?: string
  ) {
    const mainStore = useMainStore();
    if (window.location.hostname === "app.farmhelp.dk") {
      let data;
      if (error instanceof Error) {
        data = {
          farmId: mainStore.getFarmId,
          type: "UnexpectedError",
          errorName: error.name,
          errorMessage: error.message,
          errorCause: error.cause || null,
          errorStack: error.stack || null,
          source,
          functionName,
          optionalInfo: optionalInfo || null,
          version: "__VERSION__",
        };
      } else {
        data = {
          farmId: mainStore.getFarmId,
          type: "UnexpectedError",
          unkown: error,
          source,
          functionName,
          optionalInfo,
          version: "__VERSION__",
        };
      }
      console.log(data);
      const { fsDocAdd } = useFirestore();
      fsDocAdd(data, "analytics");
    }
  }

  /**
   * Wrapper that acts as a guard. Any errors will be logged to database
   * @param call File or component where error is located
   * @param onError Name of the function
   * @param source File or component where error is located
   * @param functionName Name of the function
   */
  function errorGuard(
    call: () => void,
    onError?: () => void,
    source = "undefined",
    functionName = ""
  ) {
    try {
      call();
    } catch (e) {
      console.log("Error guard: ", e);
      logError(e, source, functionName);
      if (onError !== undefined) onError();
    }
  }

  return {
    errorList,
    warningList,
    successList,
    setError,
    logError,
    setSuccess,
    setWarning,
    errorGuard,
  };
}
