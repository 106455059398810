import { createApp } from "vue";

import appComponent from "./App.vue";
import router from "./router";

import "./assets/tailwind.css";
import { fbConnect } from "./plugins/firebase";

import { createI18n } from "vue-i18n";
import dkTranslation from "./translations/dk.json";
import enTranslation from "./translations/en.json";

import { createPinia } from "pinia";

import "@fortawesome/fontawesome-free/js/solid.js";
import "@fortawesome/fontawesome-free/js/fontawesome.js";

const mode = "__MODE__";
console.log("App environment - " + mode);

fbConnect(mode);

console.log("App status - Creating app");
const app = createApp(appComponent);

console.log("App status - Creating Pinia store");
app.use(createPinia());

console.log("App status - Creating translations");
const translations = {
  dk: {
    ...dkTranslation,
  },
  en: {
    ...enTranslation,
  },
};

const i18n = createI18n({
  legacy: false,
  mode: "composition",
  messages: translations,
  fallbackRoot: true,
  silentTranslationWarn: true,
  locale: "dk",
  fallbackLocale: "en",
});
app.use(i18n);

console.log("App status - Starting router");
app.use(router);

console.log("App status - Mounting app");

app.mount("#app");
