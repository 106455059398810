import { ref } from "vue";
import { useMainStore } from "../../../stores/main";
import { fbMessagingInit } from "./firebase-messaging";
import {
  getFirestore,
  onSnapshot,
  collection,
  query,
  orderBy,
  Timestamp,
  Unsubscribe,
  writeBatch,
  doc,
} from "firebase/firestore";

export interface NotificationDoc {
  id: string;
  title: string;
  body: string;
  link?: string;
  relatedUrl?: string;
  tag?: string;
  read: boolean;
  created: Timestamp;
}
const notifications = ref<NotificationDoc[]>([]);
let listener: Unsubscribe;
export const useNotifications = () => {
  function initNotifications() {
    fbMessagingInit();
    const db = getFirestore();
    const mainStore = useMainStore();
    const queryRef = query(
      collection(db, "users/" + mainStore.user.id + "/notifications"),
      orderBy("created", "desc")
    );
    listener = onSnapshot(queryRef, async (querySnap) => {
      console.log(`Db listen notifications: ${querySnap.size}`);
      querySnap.docChanges().forEach((docChange) => {
        const docReference = docChange.doc;
        const data = {
          id: docReference.id,
          ...docReference.data(),
        } as NotificationDoc;
        if (docChange.type === "added") {
          notifications.value.push(data);
        }
        if (docChange.type === "modified") {
          const idx = notifications.value.findIndex((u) => u.id === data.id);
          notifications.value.splice(idx, 1, data);
        }
        if (docChange.type === "removed") {
          const idx = notifications.value.findIndex((u) => u.id === data.id);
          notifications.value.splice(idx, 1);
        }
      });
    });
  }

  async function setNotificationsAsRead() {
    const db = getFirestore();
    const mainStore = useMainStore();
    const batch = writeBatch(db);

    for (const notification of notifications.value.filter((n) => !n.read)) {
      const docRef = doc(
        db,
        `users/${mainStore.user.id}/notifications/${notification.id}`
      );
      batch.update(docRef, { read: true });
    }

    batch.commit();
  }

  function getNotification(notificationId: string) {
    return notifications.value.find((n) => n.id === notificationId);
  }

  function notificationsDisconnect() {
    listener();
  }

  return {
    initNotifications,
    notifications,
    setNotificationsAsRead,
    getNotification,
    notificationsDisconnect,
  };
};
