import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { useMainStore } from "../../../stores/main";

export async function fbMessagingInit() {
  const messaging = getMessaging();
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    console.log("SW test ready");
    getToken(messaging)
      .then((token) => {
        console.log("FCM token:", token);
        const mainStore = useMainStore();
        const FCMTokens = mainStore.user.FCMTokens || [];
        FCMTokens.find((t) => t === token) || FCMTokens.push(token);
        mainStore.update({ FCMTokens });
      })
      .catch((e) => console.log(e));

    // Save the token to your server or database for future use
  } else {
    console.log("Permission not granted");
  }

  onMessage(messaging, (payload) => {
    console.log("Message received:", payload);
    // Display a notification or handle the message payload
    const notificationTitle = payload.notification?.title || "";
    const notificationOptions = {
      body: payload.notification?.body,
      icon: payload.notification?.image,
    };
    new Notification(notificationTitle, notificationOptions);
  });
}
